import "./index.css";
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useState, useEffect } from "react";

// pages & components
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Navbar from "./components/Navbar";
import Landing from "./pages/Landing";
import Assessments from "./pages/Assessments";
import NewAssessment from "./pages/NewAssessment";
import Assessment from "./pages/Assessment";
import Results from "./pages/Results";
import AssessmentDetails from "./pages/AssessmentDetails";
import About from "./pages/About";

function App() {
  const { authIsReady, user } = useAuthContext();
  const [scrollingUp, setScrollingUp] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setScrollingUp(currentScrollPos < window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {/* Conditionally render the Navbar based on the route */}
          {user ? <Navbar scrollingUp={scrollingUp} /> : null}
          <Routes>
            {user ? (
              <>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/assessments" element={<Assessments />} />
                <Route path="/newassessment" element={<NewAssessment />} />
                <Route path="/results/:assessmentId" element={<Results />} />
                <Route
                  path="/assessmentDetails/:assessmentId"
                  element={<AssessmentDetails />}
                />

                {/* Updated route for individual assessment */}

                <Route
                  path="/assessment/:assessmentId"
                  element={<Assessment />}
                />

                <Route
                  path="/login"
                  element={<Navigate to="/home" replace />}
                />
                <Route
                  path="/signup"
                  element={<Navigate to="/home" replace />}
                />
              </>
            ) : (
              <>
                <Route path="/" element={<Landing />} />
                <Route
                  path="/home"
                  element={<Navigate to="/login" replace />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
