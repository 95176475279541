import { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import Dashboard from "./Dashboard";
import { quantum } from "ldrs";

export default function Home() {
  quantum.register();
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true); // Initialize loading as true
  const [apiError, setApiError] = useState(null); // State to track API errors

  // Function to make the API call
  const makeApiCall = async () => {
    try {
      // Prepare data to send in the API request
      const apiEndpoint = "https://api.quizachu.com/v1/users";
      const gauth_id = user.uid;
      const { email, displayName, photoURL, user_role } = user;

      // Create an object to store the API data
      const apiData = {
        gauth_id: gauth_id,
        email: email,
        user_role: user_role,
      };

      // Add fullname if it exists
      if (displayName) {
        apiData.fullname = displayName;
      }

      // Add profile_picture_url if it exists
      if (photoURL) {
        apiData.profile_picture_url = photoURL;
      }

      // Make the API call here
      const apiResponse = await fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(apiData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!apiResponse.ok) {
        console.error(
          "API call failed:",
          apiResponse.status,
          apiResponse.statusText
        );
        throw new Error("Could not complete the API call");
      } else {
        console.log(
          "API call succeeded:",
          apiResponse.status,
          apiResponse.statusText
        );
      }

      // Handle successful API call if needed
    } catch (error) {
      // Handle API errors
      console.error("Error during API call:", error);
      setApiError(error.message);
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or failure
    }
  };

  // Use useEffect to make the API call only when the component mounts
  useEffect(() => {
    makeApiCall();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <div className="container mx-auto px-4 pt-16 max-w-screen-xl">
      <div className="flex justify-center">
        <div className="w-full">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "70vh",
              }}
            >
              {/* Use the <quantum> component for the loading icon */}
              <l-quantum size="100" speed="1.75" color="lightgreen"></l-quantum>
            </div>
          ) : apiError ? (
            <p>Error: {apiError}</p>
          ) : (
            <Dashboard /> // Pass user data as a prop to Dashboard
          )}
        </div>
      </div>
    </div>
  );
}
