import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import LeaderboardPopup from "../components/LeaderboardPopup";
import { useAuthContext } from "../hooks/useAuthContext";

// Import the quantum component from 'ldrs'
import { quantum } from "ldrs";

export default function Assessments() {
  quantum.register();
  const { user } = useAuthContext();
  const [currentUserRole, setCurrentUserRole] = useState(null);

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch assessments from the API with user.uid as a query parameter
    const user_id = user.uid; // Replace with the user's actual ID
    fetch(`https://api.quizachu.com/v1/assessments?gauth_id=${user_id}`, {
      method: "GET",
      // Include any other necessary headers
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Update the state with the fetched assessments (assuming data.data is the array of assessments)
        setAssessments(data.data.assessments);
        setIsLoading(false);
        setCurrentUserRole(data.data.current_user_role); // Set the current user role
      })
      .catch((error) => {
        console.error("Error fetching assessments:", error);
        setError(error);
        setIsLoading(false);
      });
  }, [user]);

  if (isLoading) {
    // Show loading icon in the center of the page while data is being fetched
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        {/* Use the <quantum> component for the loading icon */}
        <l-quantum size="100" speed="1.75" color="lightgreen"></l-quantum>
      </div>
    );
  }

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center items-center h-full">
        {user && currentUserRole === "Admin" && (
          <Link to="/newassessment">
            <button className="group mt-10 mb-10 relative flex justify-center items-center rounded-md border border-gray-300 bg-hunyadi-yellow py-2 px-4 text-sm font-medium text-white shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create Assessment
            </button>
          </Link>
        )}
      </div>
      <ul className="grid grid-cols-1 gap-3 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6">
        {assessments.length === 0 ? (
          <p>No assessments available.</p>
        ) : (
          assessments.map((assessment, index) => (
            <li
              key={assessment.assessment_id}
              className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-ghost-white text-center shadow-md hover:shadow-xl transition-transform transform hover:scale-105 ${
                hoveredIndex !== null && hoveredIndex !== index
                  ? "opacity-75"
                  : "hover:bg-periwinkle"
              }`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Link to={`/assessment/${assessment.assessment_id}`}>
                <div className="mt-2 flex flex-1 flex-col p-3">
                  <img
                    className="mx-auto h-40 w-40 flex-shrink-0 rounded-full object-cover" // Adjust the size here
                    src={assessment.image_url}
                    alt={`${assessment.created_by_id.fullname}'s profile`}
                  />
                  <h3 className="mt-3 text-lg font-semibold text-air-superiority-blue">
                    {assessment.title}
                  </h3>
                  <div className="mt-1">
                    <span className="text-sm text-gray-500">
                      {assessment.created_by_id.fullname}
                    </span>
                  </div>
                </div>
              </Link>
              {currentUserRole === "Admin" && (
                <Link to={`/assessmentDetails/${assessment.assessment_id}`}>
                  <div className="flex items-center justify-center mt-2 mb-2">
                    <button className="bg-hunyadi-yellow hover:bg-air-superiority-blue text-white py-1 px-2 rounded-md text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400">
                      Edit
                    </button>
                  </div>
                </Link>
              )}

              {/* {hoveredIndex === index && assessment.leaderboard_data && (
                <LeaderboardPopup assessment={assessment} />
              )} */}
            </li>
          ))
        )}
      </ul>
    </div>
  );
}
