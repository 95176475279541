import React, { useState, useEffect, useRef } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { FaMicrophone, FaStop } from "react-icons/fa";
import { useAuthContext } from "../hooks/useAuthContext";
// Import the quantum component from 'ldrs'
import { quantum } from "ldrs";
import { waveform } from "ldrs";

const Assessment = () => {
  quantum.register();
  waveform.register();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { assessmentId } = useParams();
  const [questions, setQuestions] = useState([]); // Initialize as an empty array
  const [transcriptionResults, setTranscriptionResults] = useState({});
  const [recordingStates, setRecordingStates] = useState({});
  const mediaRecorderRefs = useRef({});
  const [isLoading, setIsLoading] = useState(true);
  const [transcriptionLoading, setTranscriptionLoading] = useState({});

  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch assessment questions from the API using assessmentId
    const fetchQuestions = async () => {
      setIsLoading(true); // Set isLoading to true when starting the fetch

      try {
        const response = await fetch(
          `https://api.quizachu.com/v1/assessments/${assessmentId}/?gauth_id=${user.uid}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Transform data into the required format
        const transformedData = {
          is_success: data.is_success,
          data: data.data.map((item) => ({
            question_id: item.question_id,
            question: item.question,
            question_type: item.question_type,
            question_description: item.question_description,
            order_seq: item.order_seq,
            options: item.options,
          })),
          message: data.message,
        };
        // console.log("Transformed data:", data.data.questions);
        setIsLoading(false); // Set isLoading to false when the fetch is complete

        // Map the response data to use "question_id" as the unique key
        const updatedQuestions = transformedData.data.map((question) => ({
          ...question,
          id: question.question_id, // Use "question_id" as the ID
          question_text: question.question, // Capture the question text
        }));

        setQuestions(updatedQuestions);

        // Initialize recordingStates based on fetched questions
        const initialRecordingStates = updatedQuestions.reduce(
          (acc, question) => {
            acc[question.id] = false;
            return acc;
          },
          {}
        );
        setRecordingStates(initialRecordingStates);
      } catch (error) {
        console.error("Error fetching assessment questions:", error);
        setError(error); // Set error state in case of an error
        setIsLoading(false); // Set isLoading to false when there is an error
      }
    };

    fetchQuestions();
  }, [assessmentId, user.uid]);

  useEffect(() => {
    questions.forEach((question) => {
      mediaRecorderRefs.current[question.id] = null;
    });
  }, [questions]);

  const toggleRecording = async (questionId) => {
    if (recordingStates[questionId]) {
      stopRecording(questionId);
    } else {
      await startRecording(questionId);
    }
  };

  const renderButtonOrLoader = (questionId) => {
    const isTranscribed =
      transcriptionResults[questionId] &&
      transcriptionResults[questionId].trim() !== "";

    if (recordingStates[questionId]) {
      // Recording is in progress - show the stop icon
      // The icon color is orange if not transcribed yet, otherwise default (or any other color)
      return <FaStop style={{ color: isTranscribed ? undefined : "orange" }} />;
    } else if (transcriptionLoading[questionId]) {
      // Transcription is in progress - show the loader
      return (
        <l-waveform size="15" stroke="1.5" speed="1" color="green"></l-waveform>
      );
    } else {
      // Return the microphone icon with color based on transcription status
      // Green if transcribed, orange if not
      return (
        <FaMicrophone style={{ color: isTranscribed ? "green" : "orange" }} />
      );
    }
  };

  const handleTextChange = (e, questionId) => {
    setTranscriptionResults((prev) => ({
      ...prev,
      [questionId]: e.target.value,
    }));
  };

  const allQuestionsAnswered = () => {
    return questions.every((question) => transcriptionResults[question.id]);
  };

  const startRecording = async (questionId) => {
    try {
      console.log(`Start recording for question ${questionId}`);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRefs.current[questionId] = mediaRecorder;
      const chunks = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        console.log(`Recording stopped for question ${questionId}`);
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        console.log(`Audio blob created for question ${questionId}`);
        await transcribeAudio(audioBlob, questionId);
      };

      mediaRecorder.start();
      setRecordingStates((prev) => ({ ...prev, [questionId]: true }));
    } catch (error) {
      console.error(
        `Error accessing microphone for question ${questionId}:`,
        error
      );
    }
  };

  const stopRecording = (questionId) => {
    if (mediaRecorderRefs.current[questionId]?.state === "recording") {
      console.log(`Stop recording for question ${questionId}`);
      mediaRecorderRefs.current[questionId].stop();
      setRecordingStates((prev) => ({ ...prev, [questionId]: false }));
    }
  };

  const transcribeAudio = async (audioBlob, questionId) => {
    const formData = new FormData();
    formData.append("file", audioBlob, `recorded_audio_${questionId}.wav`);
    setTranscriptionLoading((prevLoading) => ({
      ...prevLoading,
      [questionId]: true,
    }));

    try {
      console.log(`Sending API request for question ${questionId}`);
      // const response = await fetch("http://localhost:8000/transcribe/", {
      const response = await fetch(
        "https://quizachu-backend-h67ch72r3q-ew.a.run.app/transcribe",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log(`API response received for question ${questionId}`);
        const data = await response.json();
        setTranscriptionResults((prev) => ({
          ...prev,
          [questionId]: data, // Assuming 'text' is the key for the transcription result
        }));
        console.log(`Transcription result for question ${questionId}:`, data);
      } else {
        console.error(
          `Error sending audio to the API for question ${questionId}`
        );
        setTranscriptionResults((prev) => ({
          ...prev,
          [questionId]: "Error in transcription.",
        }));
      }

      setTranscriptionLoading((prevLoading) => ({
        ...prevLoading,
        [questionId]: false,
      }));
    } catch (error) {
      console.error(
        `Error transcribing audio for question ${questionId}:`,
        error
      );
      setTranscriptionResults((prev) => ({
        ...prev,
        [questionId]: "Error in transcription.",
      }));
      setTranscriptionLoading((prevLoading) => ({
        ...prevLoading,
        [questionId]: false,
      }));
    }
  };

  const handleSubmit = async () => {
    // Create an array to store the user's answers in the required format
    const userAnswers = [];

    // Loop through each question and add its user answer to the array
    questions.forEach((question) => {
      const userAnswer = transcriptionResults[question.id] || "";
      userAnswers.push({
        question_id: question.id,
        user_answer: userAnswer,
      });
    });

    // Create the request body with the user's answers
    const requestBody = {
      data: userAnswers,
    };

    try {
      // Send a POST request to submit the user's answers
      const response = await fetch(
        `https://api.quizachu.com/v1/user/assessment/${assessmentId}/submit?gauth_id=${user.uid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("API request failed");
      }

      // Handle the API response here if needed
      console.log("User answers submitted successfully!");
      // navigate("/home"); // Redirect to the  dashboard
      navigate(`/results/${assessmentId}`);
    } catch (error) {
      console.error("Error submitting user answers:", error);
      // Handle the error as needed
    }
  };

  if (isLoading) {
    // Show loading icon in the center of the page while data is being fetched
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        {/* Use the <quantum> component for the loading icon */}
        <l-quantum size="100" speed="1.75" color="lightgreen"></l-quantum>
      </div>
    );
  }

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Assessment</h2>
      {questions.map((question, index) => (
        <div key={question.id} className="mb-4">
          <p className="text-lg font-semibold mb-2">
            {index + 1}. {question.question_text}
          </p>
          <textarea
            className={`w-full p-2 mb-2 border rounded ${
              transcriptionResults[question.id]
                ? "border-green-500"
                : "border-gray-300"
            }`}
            rows="2"
            placeholder="Your answer or press record"
            value={transcriptionResults[question.id] || ""}
            onChange={(e) => handleTextChange(e, question.id)}
          />
          <button
            className={`p-2 rounded ${
              recordingStates[question.id] || transcriptionLoading[question.id]
                ? "bg-white text-green"
                : "bg-hunyadi-yellow-500 text-white mr-2"
            }`}
            onClick={() => toggleRecording(question.id)}
          >
            {renderButtonOrLoader(question.id)}
          </button>
        </div>
      ))}

      <button
        onClick={handleSubmit}
        className={`mt-4 ${
          allQuestionsAnswered() ? "bg-green-500" : "bg-air-superiority-blue"
        } text-white py-2 px-4 rounded-md hover:bg-penn-blue transition duration-300`}
        disabled={!allQuestionsAnswered()}
      >
        Submit Answers
      </button>
    </div>
  );
};

export default Assessment;
