import React, { useState } from "react";
import useLogin from "../hooks/useLogin";
import { useSignup } from "../hooks/useSignup";
import { Link } from "react-router-dom"; // Import Link from React Router
import { FcGoogle } from "react-icons/fc"; // Import the Google icon

export default function Login() {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { googleLogin } = useLogin();
  const { error, isPending, signup } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(email, password, displayName);
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-6 py-12">
      <div className="w-full max-w-md space-y-8">
        <div className="flex justify-center">
          <img
            className="h-[220px] w-[220px] rounded-full border-2 border-indigo-300"
            src="/quizachu_02.png"
            alt="Quizachu"
          />
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-hunyadi-yellow py-2 px-4 text-sm font-medium text-white hover:bg-air-superiority-blue focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isPending ? "Signing up" : "Sign up"}
            </button>
          </div>
        </form>

        <div>
          <button
            onClick={googleLogin}
            className="group relative flex w-full justify-center items-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FcGoogle className="w-6 h-6 mr-3" />
            Sign in with Google
          </button>
        </div>

        <div className="text-sm text-center">
          Already have an account?
          <Link
            to="/login"
            className="font-medium text-air-superiority-blue hover:text-penn-blue"
          >
            {" "}
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
}
