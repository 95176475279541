import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { grid } from "ldrs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { cardio } from "ldrs";
import { quantum } from "ldrs";

export default function Results() {
  grid.register();
  cardio.register();
  quantum.register();
  const { user } = useAuthContext();
  const { assessmentId } = useParams();
  const [resultsData, setResultsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshInterval = 1000; // Refresh interval in milliseconds

  const fetchData = () => {
    fetch(
      `https://api.quizachu.com/v1/user/assessment/${assessmentId}/results?gauth_id=${user.uid}`
    )
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        setResultsData(data.data);
        setLoading(false);
        if (
          data.data.some(
            (item) => item.answer_evaluation_label === "Evaluation Pending"
          )
        ) {
          setTimeout(fetchData, refreshInterval); // Continue fetching if there are pending evaluations
        }
      })
      .catch((error) => {
        console.error("Error fetching results:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(); // Initial data fetch
  }, [assessmentId, user.uid]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    ); // Add a spinner or animation here
  }

  const getIconColor = (isCorrect, isSimilar) => {
    if (isCorrect) return <ThumbUpAltIcon className="text-green-500" />;
    if (!isSimilar) return <ThumbDownAltIcon className="text-red-500" />;
    return <ThumbUpAltIcon className="text-blue-500" />;
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold leading-7 text-gray-800 mb-4">
        Assessment Results
      </h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        {resultsData.map((item, index) => {
          const isCorrect = item.is_correct;
          const bgColor =
            item.answer_evaluation_label === "Evaluation Pending"
              ? "bg-ghost-white"
              : isCorrect
              ? "bg-green-200"
              : "bg-red-200";

          const IconComponent = getIconColor(
            isCorrect,
            item.answer_evaluation_label === "entailment"
          );
          const status =
            item.answer_evaluation_label === "Entailment"
              ? "Similar"
              : item.answer_evaluation_label;

          const isPending =
            item.answer_evaluation_label === "Evaluation Pending";

          return (
            <div
              key={item.assessment_question_id}
              className={`rounded-lg p-4 ${bgColor} border border-gray-300 transition duration-300 transform hover:scale-105 hover:shadow-lg`}
            >
              <div className="grid grid-cols-1 gap-4">
                <div className="col-span-1 text-penn-blue">
                  <div className="flex items-center justify-between rounded-lg">
                    <p className="text-lg  font-semibold">
                      Question {index + 1}
                    </p>
                    <div className="flex items-center">
                      {isPending ? (
                        <l-quantum
                          size="45"
                          speed="1.75"
                          color="black"
                        ></l-quantum>
                      ) : (
                        <>
                          {IconComponent}
                          <p
                            className={`font-semibold ml-2 mx-2 ${
                              isCorrect ? "text-green-600" : "text-red-600"
                            }`}
                          >
                            {isCorrect ? "Correct" : "Incorrect"}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <p className="mt-2 text-sm text-gray-700">
                    {item.assessment_question}
                  </p>
                </div>
                <div className="col-span-1 grid grid-cols-3 gap-4 mt-2">
                  <div className="col-span-1 text-penn-blue">
                    <p className="font-semibold">Your Response:</p>
                    <p className="text-sm">{item.user_assessment_respone}</p>
                  </div>

                  <div className="col-span-1 text-penn-blue">
                    <p className="font-semibold">{status}</p>
                    {!isPending &&
                      item.answer_evaluation_label !== "entailment" && (
                        <p className="text-sm">
                          {/* Apply Math.round() to the probability score */}
                          {Math.round(parseFloat(item.probability_score))}%
                        </p>
                      )}
                  </div>
                  <div className="col-span-1 text-penn-blue">
                    <p className="font-semibold">Gold Answer:</p>
                    <p className="text-sm">{item.answers[0].option}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
