import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function About() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    // Add more configuration options as needed
  };

  // Generate an array with image filenames from 1.jpg to 18.jpg
  const imageList = ["1.jpg", "5.jpg", "7.jpg", "8.jpg", "20.jpg", "21.jpg"];

  // "10.jpg",
  // "11.jpg",
  // "12.jpg",
  // "13.jpg",
  // "14.jpg",
  // "15.jpg",
  // "16.jpg",
  //    "17.jpg",
  // "19.jpg",
  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, left: "10px", zIndex: 1 }}
        onClick={onClick}
      >
        {/* Custom prev button content */}
      </div>
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, right: "10px", zIndex: 1 }}
        onClick={onClick}
      >
        {/* Custom next button content */}
      </div>
    );
  }

  return (
    <div className="w-full">
      <Slider {...sliderSettings}>
        {imageList.map((imageName, index) => (
          <div key={index} className="px-4">
            <img
              src={`/about/${imageName}`}
              alt={`Slide ${index + 1}`}
              style={{
                maxWidth: "90%", // You can adjust this value
                maxHeight: "90%", // And this value as needed
                margin: "auto", // This will center the image if it's smaller than the slide
              }}
              className="w-full"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default About;
