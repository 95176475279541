import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Dashboard({ user_role }) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const viewResults = (assessmentId) => {
    // Fetch the results from the backend API
    fetch(
      `https://api.quizachu.com/v1/user/assessment/${assessmentId}/results?gauth_id=${user.uid}`
    )
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        // Assuming you want to navigate to a results page and pass state
        navigate(`/results/${assessmentId}`, { state: { data } });
      })
      .catch((error) => {
        console.error("Error fetching results:", error);
      });
  };

  useEffect(() => {
    fetch(
      `https://api.quizachu.com/v1/user/${user.uid}/dashboard?gauth_id=${user.uid}`
    )
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        setDashboardData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [user.uid]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        {/* Replace with your loading component */}
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <main>
        <header>
          <h2 className="text-2xl font-semibold leading-7 text-gray-800 mb-4">
            Historical Assessment Data
          </h2>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {dashboardData && (
            <>
              <div className="bg-ghost-white shadow-lg rounded-lg p-4 hover:bg-periwinkle">
                <p className="text-sm font-medium text-gray-400">User ID</p>
                <p className="text-xl font-semibold">
                  {dashboardData.user_id ?? "N/A"}
                </p>
              </div>
              <div className="bg-ghost-white shadow-lg rounded-lg p-4  hover:bg-periwinkle">
                <p className="text-sm font-medium text-gray-400">
                  Assessment Count
                </p>
                <p className="text-xl font-semibold">
                  {dashboardData.assessment_count ?? "N/A"}
                </p>
              </div>
              <div className="bg-ghost-white shadow-lg rounded-lg p-4 hover-bg-periwinkle">
                <p className="text-sm font-medium text-gray-400">
                  Average Score
                </p>
                <p className="text-xl font-semibold">
                  {dashboardData.avg_score ?? "N/A"}%
                </p>
              </div>
              <div className="bg-ghost-white shadow-lg rounded-lg p-4 hover-bg-periwinkle">
                <p className="text-sm font-medium text-gray-400">
                  Highest Score
                </p>
                <p className="text-xl font-semibold">
                  {dashboardData.highest_score ?? "N/A"}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="bg-periwinkle shadow-lg rounded-lg p-4">
          <table className="min-w-full divide-y divide-gray">
            <thead>
              <tr>
                <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Recent Score
                </th>
                <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Percentage
                </th>
                <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Attempts
                </th>
                <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Details
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dashboardData?.history?.map((assessment) => (
                <tr key={assessment.user_assessment_id}>
                  <td className="px-4 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {assessment.assessment_details.title ?? "N/A"}
                  </td>
                  <td className="px-4 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {assessment.marks_obtained ?? "N/A"} /{" "}
                    {assessment.assessment_details.ques_count ?? "N/A"}
                  </td>
                  <td className="px-4 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {assessment.percentage ?? "N/A"}%
                  </td>
                  <td className="px-4 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {assessment.attempts_taken ?? "N/A"}
                  </td>
                  <td className="px-4 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={() => viewResults(assessment.assessment_id)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      View Results
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}
