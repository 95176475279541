import React from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Navbar() {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  return (
    <Disclosure as="nav" className="bg-periwinkle shadow-md">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between items-center">
              <div className="flex items-center">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="text-penn-blue">
                    {open ? (
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <NavLink
                    to="/home"
                    className="text-xl font-bold text-gray-800 hover:text-air-superiority-blue"
                  >
                    <div className="flex items-center">
                      <img
                        className="h-14 w-14 rounded-full border-2 border-indigo-100 mr-2"
                        src="/quizachu_02.png"
                        alt="Quizachu"
                      />
                      Quizachu
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="hidden sm:block">
                <div className="flex space-x-4">
                  {!user ? (
                    <>
                      <NavLink
                        to="/login"
                        className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-hunyadi-yellow hover:text-white"
                      >
                        Login
                      </NavLink>
                      <NavLink
                        to="/signup"
                        className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-hunyadi-yellow hover:text-white"
                      >
                        Sign Up
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink
                        to="/assessments"
                        className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-hunyadi-yellow hover:text-white"
                      >
                        Assessments
                      </NavLink>
                      <NavLink
                        to="/about"
                        className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-hunyadi-yellow hover:text-white"
                      >
                        About
                      </NavLink>
                      <button
                        onClick={logout}
                        className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-hunyadi-yellow hover:text-white"
                      >
                        Sign Out
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {!user ? (
                <>
                  <NavLink
                    to="/login"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-800 hover:text-white"
                  >
                    Login
                  </NavLink>
                  <NavLink
                    to="/signup"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-800 hover:text-white"
                  >
                    Sign Up
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/assessments"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-800 hover:text-white"
                  >
                    Assessments
                  </NavLink>
                  <button
                    onClick={logout}
                    className="px-3 py-2 mt-4 rounded-md text-base font-medium text-gray-700 hover:bg-hunyadi-yellow hover:text-white"
                  >
                    Sign Out
                  </button>
                </>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
