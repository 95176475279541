import React, { useState, useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useAuthContext } from "../hooks/useAuthContext";
import useImageUpload from "../hooks/useImageUpload";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Import the quantum component from 'ldrs'
import { helix } from "ldrs";

export default function NewAssessment() {
  helix.register();
  const { user } = useAuthContext();
  const [formData, setFormData] = useState({
    title: "",
    context: "",
    // description: "",
    image_url: "",
    passmark: "",
    ques_count: "",
    category: "",
  });

  const navigate = useNavigate(); // Initialize navigate
  const [wordCount, setWordCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [file, setFile] = useState(null); // New state for the file
  const { progress, url, error } = useImageUpload(file, user.uid); // Using the custom hook

  // Add CSS styles for centering the loader
  const loaderStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  useEffect(() => {
    if (url) {
      setFormData({ ...formData, image_url: url });
    }
  }, [url]); // Update formData when the URL is available

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleContextChange = (e) => {
    const words = e.target.value.trim().split(/\s+/);
    if (words.length <= 2000) {
      setFormData({ ...formData, context: e.target.value });
      setWordCount(words.length);
    } else {
      setWordCount(2000); // Keep word count at 1000 if it exceeds the limit
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    // Make sure the image is fully uploaded before submitting
    if (file && !url) {
      setApiError("Please wait for the image to finish uploading");
      setIsLoading(false);
      return;
    }

    try {
      // Log the formData before making the API request
      console.log("Form Data:", formData);

      const response = await fetch(
        `https://api.quizachu.com/v1/assessments/create?gauth_id=${user.uid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("API request failed");
      }

      // Navigate to the assessment details page with the newly created assessment_id
      const responseData = await response.json();
      const newAssessmentId = responseData.data.assessment_id;
      navigate(`/assessmentDetails/${newAssessmentId}`);
    } catch (error) {
      console.error("Error during API call:", error);
      setApiError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mx-8 my-8">
      {isLoading && (
        <div style={loaderStyles}>
          {/* Use the <helix> component for the loading icon */}
          <l-helix size="100" speed="1.75" color="lightgreen"></l-helix>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="px-4 py-5 sm:p-2">
          <div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Assessment Name
              </label>
              <input
                type="text"
                name="title"
                id="title"
                autoComplete="title"
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                onChange={handleChange}
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="context"
                className="block text-sm font-medium text-gray-700"
              >
                Assessment Material
              </label>
              <textarea
                id="context"
                name="context"
                rows={6}
                className="mt-1 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                value={formData.context}
                onChange={handleContextChange}
              />
              <p
                className={`text-sm ${
                  wordCount === 2000 ? "text-red-500" : "text-gray-600"
                }`}
              >
                Words: {wordCount}/2000
              </p>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="image_url"
                className="block text-sm font-medium text-gray-700"
              >
                Assessment Documentation (PDF/DOC/JPG)
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <PhotoIcon className="mx-auto h-6 w-6 text-indigo-600" />
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                    >
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="image_url"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">JPG</p>
                </div>
              </div>
            </div>

            {/* <div className="sm:col-span-2">
              <label
                htmlFor="ques_count"
                className="block text-sm font-medium text-gray-700"
              >
                Number of Questions
              </label>
              <select
                id="ques_count"
                name="ques_count"
                autoComplete="ques_count"
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                onChange={handleChange}
              >
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
              </select>
            </div> */}

            {/* <div className="sm:col-span-2">
              <label
                htmlFor="duration"
                className="block text-sm font-medium text-gray-700"
              >
                Duration
              </label>
              <select
                id="duration"
                name="duration"
                autoComplete="duration"
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                onChange={handleChange}
              >
                <option>10 Mins</option>
                <option>20 Mins</option>
                <option>30 Mins</option>
                <option>60 Mins</option>
              </select>
            </div> */}
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
