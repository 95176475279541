import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCancel } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function AssessmentDetails() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { assessmentId } = useParams();
  const [details, setDetails] = useState(null);
  const [initialQuestions, setInitialQuestions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedQuestionId, setEditedQuestionId] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedAnswer, setEditedAnswer] = useState("");

  useEffect(() => {
    fetch(
      `https://api.quizachu.com/v1/assessments/${assessmentId}/details?gauth_id=${user.uid}`
    )
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch details");
        return response.json();
      })
      .then((data) => {
        if (data.is_success) {
          setDetails(data.data);
          setInitialQuestions(data.data?.questions); // Store initial questions
        } else {
          setError(new Error("Failed to fetch details"));
        }
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  }, [assessmentId]);

  const removeQuestion = (questionId) => {
    // Update the initialQuestions state instead of details
    const updatedQuestions = initialQuestions.filter(
      (question) => question.question_id !== questionId
    );
    setInitialQuestions(updatedQuestions);
  };

  const handleEdit = (question) => {
    setEditedQuestionId(question.question_id);
    setEditedQuestion(question.question);
    setEditedAnswer(question.options.map((option) => option.option).join(", "));
    setIsEditing(true);
  };

  const handleSaveEdit = () => {
    // Update the edited question in the temporary state
    const updatedQuestions = initialQuestions.map((question) => {
      if (question.question_id === editedQuestionId) {
        return {
          ...question,
          question: editedQuestion, // Update the question text
          options: [{ option: editedAnswer }],
        };
      }
      return question;
    });

    setInitialQuestions(updatedQuestions);
    setIsEditing(false);
  };

  const handlePublish = () => {
    // Create an array of questions directly
    const dataToBeSent = {
      data: initialQuestions.map((question) => ({
        question_id: question.question_id,
        question: question.question,
        answer: question.options.map((option) => option.option).join(", "),
      })),
    };

    // Send a POST request to the target API with the array of questions
    fetch(
      `https://api.quizachu.com/v1/assessments/${assessmentId}/publish?gauth_id=${user.uid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToBeSent),
      }
    )
      .then((response) => {
        if (!response.ok) throw new Error("Failed to publish assessment");
        return response.json();
      })
      .then((responseData) => {
        // Handle the response from the API here
        console.log("Publish response data:", responseData);
        // You can set the response data to a state variable if needed
        // Example: setPublishedData(responseData);
        navigate("/home"); // Redirect to the  dashboard

        // Optionally, you can perform any additional actions after publishing
        // Example: show a success message, redirect to another page, etc.
      })
      .catch((error) => {
        console.error("Error publishing assessment:", error);
      });
  };

  if (isLoading) return <div className="text-center mt-4">Loading...</div>;
  if (error)
    return <div className="text-center mt-4">Error: {error.message}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-4">
        <h2 className="text-center text-2xl font-bold mb-6 text-penn-blue">
          {details?.title}
        </h2>

        <table className="mt-6 min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">
                Options
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">
                Question
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">
                Answer/Option
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">
                Confidence Score
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 mb-4">
            {initialQuestions?.map((question) => (
              <tr key={question.question_id}>
                <td className="px-6 py-4  max-w-[20px]">
                  <button
                    className="text-penn-blue hover:text-blue-800"
                    onClick={() => handleEdit(question)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    className="text-red-600 hover:text-red-800 ml-4"
                    onClick={() => removeQuestion(question.question_id)}
                  >
                    <FontAwesomeIcon icon={faCancel} />
                  </button>
                </td>
                <td className="px-6 py-4  max-w-[600px] text-gray-800">
                  {question.question}
                </td>
                <td className="px-6 py-4  max-w-[600px] text-gray-800">
                  {question.options.map((option) => option.option).join(", ")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-800">
                  {question.confidence_score !== undefined ? (
                    <div className="relative">
                      <div
                        className={`h-4 w-full rounded-md ${
                          question.confidence_score >= 0.75
                            ? "bg-green-500"
                            : question.confidence_score > 0.5
                            ? "bg-yellow-500"
                            : "bg-red-500"
                        }`}
                        style={{
                          width: `${question.confidence_score * 100}%`,
                        }}
                      ></div>
                      <span className="absolute inset-y-0 right-0 pr-2 flex items-center">
                        {question.confidence_score.toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for editing */}
        {isEditing && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-40"></div>
            <div className="bg-white p-4 rounded-lg shadow-lg z-10">
              <h3 className="text-lg font-semibold mb-2">
                Update Question and Answer
              </h3>
              <textarea
                className="w-full p-2 border rounded mb-2"
                rows="4"
                value={editedQuestion}
                onChange={(e) => setEditedQuestion(e.target.value)}
              ></textarea>
              <textarea
                className="w-full p-2 border rounded mb-2"
                rows="4"
                value={editedAnswer}
                onChange={(e) => setEditedAnswer(e.target.value)}
              ></textarea>
              <div className="flex justify-end">
                <button
                  className="bg-penn-blue hover:bg-hunyadi-yellow text-white py-2 px-4 rounded-md shadow-md mr-2"
                  onClick={handleSaveEdit}
                >
                  Save
                </button>
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md shadow-md"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mt-4 text-center mt-6">
          <button
            className="bg-penn-blue hover:bg-hunyadi-yellow text-white py-2 px-4 rounded-md shadow-md"
            onClick={handlePublish}
          >
            Save Assessment
          </button>
        </div>
      </div>
    </div>
  );
}
