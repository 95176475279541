import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDhzKl-8m9wgl5xExddP7nYyKd9SasOAc",
  authDomain: "quizachu-fe.firebaseapp.com",
  projectId: "quizachu-fe",
  storageBucket: "quizachu-fe.appspot.com",
  messagingSenderId: "828238919170",
  appId: "1:828238919170:web:331ff566c92df24946991b",
  measurementId: "G-P99X0JQ8ZL",
};

// init firebase
const app = initializeApp(firebaseConfig);

// init firestore service
const projectFirestore = getFirestore(app);
const projectAuth = getAuth(app);
const projectStorage = getStorage(app);

// export the firestore service
export { projectFirestore, projectAuth, Timestamp, projectStorage, app };
